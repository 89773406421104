<template>
    <AModal
        :visible="visible"
        title="Lihat Gambar"
        @cancel="handleModalCancel"
        :destroy-on-close="true"
        :footer="null">
        <AImage
            :src="state.image"/>
    </AModal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
} from 'vue'

export default defineComponent({
    components: {},
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {

        const state = reactive({
            image: null,
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        onMounted(() => {
            state.image = props.item.image
        })

        return {
            state,
            handleModalCancel,
        }
    },
})
</script>
