<template>
    <AModal
        :visible="visible"
        title="Details"
        width="90%"
        @cancel="handleModalCancel"
        :footer="null">
        <MdTable
            :columns="state.columns"
            :data-source="state.data"
            size="small"
            :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)">

            <template #image="{ record }">
                <ASpace>
                    <ATooltip
                        v-if="record.image"
                        :title="`Lihat ${isUrlPdf(record.image) ? 'PDF' : 'Gambar'}`">
                        <AButton
                            v-if="isUrlPdf(record.image)"
                            class="button"
                            size="small"
                            :href="record.image"
                            target="blank">
                            <template #icon><FilePdfOutlined /></template>
                            Lihat PDF
                        </AButton>
                        <AButton
                            v-else
                            class="button"
                            size="small"
                            @click="btnImage(record)">
                            <template #icon><FileImageOutlined /></template>
                            Lihat Gambar
                        </AButton>
                    </ATooltip>
                </ASpace>
            </template>
        </MdTable>

        <!-- detail image -->
        <ImageDetail
            v-if="visibleImageModal"
            v-model:visible="visibleImageModal"
            :item="visibleImageItemModal"/>
    </AModal>
</template>

<script>
import { defineComponent, reactive, onMounted, ref } from 'vue'
import { FileImageOutlined, FilePdfOutlined } from '@ant-design/icons-vue'
import ImageDetail from './ImageDetail'
import {
    isUrlPdf,
} from '@/helpers/utils'

export default defineComponent({
    components: {
        ImageDetail,
        FileImageOutlined,
        FilePdfOutlined,
    },
    props: {
        visible: [Boolean],
        items: {
            type: [Array],
            default: () => [],
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const state = reactive({
            data: [],
            columns: [
                {
                    title: 'Tanggal',
                    dataIndex: 'date',
                },
                {
                    title: 'Kode Toko',
                    dataIndex: 'customer_code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'customer_name',
                },
                {
                    title: 'Pengguna',
                    dataIndex: 'username',
                },
                {
                    title: 'Kategori',
                    dataIndex: 'header',
                },
                {
                    title: 'Catatan',
                    dataIndex: 'rejection_note',
                },
                {
                    title: 'Gambar/PDF',
                    slots: { customRender: 'image' },
                },
                {
                    title: 'Status Approval',
                    dataIndex: 'status_approval',
                },
            ],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:items', [])
        }

        // handle images
        const visibleImageModal = ref(false);
        const visibleImageItemModal = ref(null);

        const btnImage = (item = null, readOnly = false) => {
            visibleImageModal.value = true
            visibleImageItemModal.value = {
                ...item,
                readOnly,
            }
        }

        // handle vue
        onMounted(() => {
            state.data = props.items
        })

        return {
            handleModalCancel,
            state,
            btnImage,
            visibleImageModal,
            visibleImageItemModal,
            // helpers
            isUrlPdf,
        }
    },
})
</script>